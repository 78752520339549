import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ popUpOpen, setPopUpOpen,nextQstn,setNextQstn,challengePage,arrayLen,challengeType}) {
  //const [nextQstn,setNextQstn]=React.useState(false);
  const handleClose = () => {
    setPopUpOpen({ ...popUpOpen, open: false,nextQstn:true });
    setNextQstn({...nextQstn,isTrue:true})
  };

  const handleCloseLast = () => {
    setPopUpOpen({ ...popUpOpen, open: false });
  };
console.log(challengePage,"page in ans dailog")
console.log(arrayLen,"array len in ans dailog")
  return (
    <div>
      <Dialog onClose={handleClose} open={popUpOpen.open} fullWidth maxWidth="sm">
        {/* <DialogTitle onClose={handleClose}>ANSWER</DialogTitle> */}
        <DialogContent dividers>
          <div
            style={{ width: "100%" }}
            dangerouslySetInnerHTML={{ __html: popUpOpen.data }}
          ></div>
        </DialogContent>
        <DialogActions>
        {challengePage!==arrayLen-1 && challengeType==="1-by-1"?<Button autoFocus onClick={handleClose} color="primary">
           Next Question
          </Button>:null}
          {challengePage===arrayLen-1 && challengeType==="1-by-1"?<Button autoFocus onClick={handleCloseLast} color="primary">
           Close
          </Button>:null}
          {challengeType==="4-by-4"?<Button autoFocus onClick={handleCloseLast} color="primary">
           Close
          </Button>:null}
        </DialogActions>
      </Dialog>
    </div>
  );
}